<!--
 * @Author       : JiangChao
 * @Date         : 2022-11-28 14:36:24
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-03-17 23:42:02
 * @Description  : 訂單詳情頁
-->
<template>
    <div class="container">
        <div class="status">
            <span v-if="info.orderStatus == 'PENDING_PAYMENT'">待支付</span>
            <span v-if="info.orderStatus == 'USING'">使用中</span>
            <span v-if="info.orderStatus == 'DONE'">已完成</span>
            <span v-if="info.orderStatus == 'WAITING_PAID'">待扣款</span>
            <span v-if="info.orderStatus == 'REFUNDED'">已退款</span>
        </div>
        <div class="detail">
            <div class="item">
                <div>編號：</div>
                <div>{{ info.orderSn }}</div>
            </div>
            <div class="item">
                <div>機器編號：</div>
                <div>{{ info.deviceSn }}</div>
            </div>
            <div class="item" v-if="isPoOrder">
                <div>租借地點：</div>
                <div>{{ this.storeName }}</div>
            </div>

            <div class="item">
                <div>租借時間：</div>
                <div>{{ info.createTime }}</div>
            </div>
            <div class="item">
                <div>歸還地點：</div>
                <div>{{ info.backAddress }}</div>
            </div>
            <div class="item">
                <div>歸還時間：</div>
                <div>{{ info.backTime }}</div>
            </div>
            <div class="item">
                <div>計費時數（小時）：</div>
                <div>{{ info.usingTime }}小時</div>
            </div>
            <div class="item">
                <div>優惠折扣：</div>
                <div>{{ info.duration }}小時</div>
            </div>
            <div class="item">
                <div>費用：</div>
                <div>NT${{ info.money }}</div>
            </div>
        </div>
        <div class="bottom-name">
            <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";

export default {
    name: "",
    data() {
        return {
            show: true,
            orderSn: "",
            info: {},
            storeName: "",
            isPoOrder: false,
        };
    },
    beforeCreate() {
        // const token = localStorage.getItem("token");
		const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
    methods: {
        async getOrderDetail() {
            try {
                const res = await this.$axios.get(
                    `customer/order/detail?orderSn=${this.orderSn}`
                );
                if (res.data.success) {
                    console.log("res: ", res);
                    this.info = res.data.result;
                    if (this.info.orderStoreSnapshot) {
                        this.isPoOrder = true;
                        this.storeName = this.info.orderStoreSnapshot.storeName;
                    } else {
                        this.isPoOrder = false;
                    }
                } else {
                    throw res;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {},
    mounted() {
        this.orderSn = this.$route.params.sn;
        console.log("this.orderSn: ", this.orderSn);

        this.getOrderDetail();
    },
    created() {},
};
</script>

<style scoped lang="less">
.status {
    height: 150px;
    line-height: 150px;
    background: #fcebb0;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}
.detail {
    margin: 10px;
    border-radius: 8px;
    .item {
        display: flex;
        background: #ffffff;
        flex-direction: row;
        justify-content: space-between;
        line-height: 50px;
        height: 50px;
        padding: 3px 10px;
        margin-top: 1px;
    }
}
</style>
